<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Security Deposit Report
            <v-spacer></v-spacer>
            <v-download-column-select
              v-if="
                ($auth.isAdmin() ||
                  $auth.loggedInRole().slug === 'administrator' ||
                  $auth.loggedInRole().slug === 'accountant') &&
                this.items.length > 0
              "
              :optionType="'radio'"
              :showPDF="false"
              :downloadExcelText="'Begin Download/Upload'"
              @onDownload="downloadResult"
            >
              DOWNLOAD/UPLOAD OPTION
            </v-download-column-select>
          </v-card-title>
          <v-card outlined>
            <v-card-title class="title pb-0">
              <v-flex xs4 sm4>
                <v-grade-field
                  v-model="grade"
                  :inside="true"
                  :with-all="true"
                ></v-grade-field>
              </v-flex>
              <v-flex xs4 sm4>
                <search-button
                  :disabled="!grade"
                  style=""
                  permission="subject-read"
                  @action="get()"
                >
                  Search
                </search-button>
              </v-flex>
            </v-card-title>
          </v-card>
          <v-data-table
            :headers="headers"
            :items="items"
            :footer-props="footerProps"
            :options.sync="pagination"
            :server-items-length="items.length"
            :loading="items.loading"
          >
            <template v-slot:item="{ item, index }">
              <tr>
                <td class="text-xs-left">{{ index + 1 }}</td>
                <td class="text-xs-left">{{ item.roll ? item.roll : '-' }}</td>
                <td class="text-xs-left">
                  {{ item.enroll_code ? item.enroll_code : '-' }}
                </td>
                <td class="text-xs-left">{{ item.name ? item.name : '-' }}</td>
                <td class="text-xs-left">
                  {{ item.grade.replace(/"/g, ' ') }} /
                  {{ item.section.replace(/"/g, ' ') }}
                </td>
                <td class="text-xs-left">
                  {{
                    item.security
                      ? item.security.opening_amount.currency()
                      : '-'
                  }}
                </td>
                <td class="text-right">
                  {{ item.security ? item.security.amount.currency() : '-' }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import Mixins from '@/library/Mixins';
import {redirectDownloadUrl} from "../../../../library/helpers"
export default {
  mixins: [Mixins],
  data: () => ({
    grade: '',
    pagination: {
      totalItems: 0,
      itemsPerPage: 50,
    },
    items: [],
    downloadUrl: '',
    headers: [
      { text: 'S.No.', align: 'left', value: 'seriel_no', sortable: false },
      { text: 'Roll', align: 'left', value: 'id', sortable: false },
      {
        text: 'Enroll Code',
        align: 'left',
        value: 'enroll_code',
        sortable: false,
      },
      { text: 'Name', align: 'left', value: 'name', sortable: false },
      {
        text: 'Grade / Section',
        align: 'left',
        value: 'grade',
        sortable: false,
      },
      {
        text: 'Opening Amount',
        align: 'left',
        value: 'opening_amount',
        sortable: false,
      },
      { text: 'Amount', align: 'right', value: 'amount', sortable: false },
    ],
  }),
  watch: {
    pagination: function () {
      this.get();
    },
  },
  methods: {
    get() {
      let extraParams;
      if (this.grade) extraParams = `gradeId=${this.grade}`;

      this.$rest
        .get('/api/security-deposit' + this.queryString(extraParams))
        .then(({ data }) => {
          this.items = data.data;
          this.downloadUrl = data.download_url;
        })
        .catch(() => {})
        .finally(() => {});
    },
    downloadResult() {
      //https://reporting.sahakaryatech.com/download/billing/security-deposit/1:40?gradeId=all&type=excel
      // window.open(this.downloadUrl + 'excel', '_blank');
      // passDataToExternalDomain("reporting");
      redirectDownloadUrl({
          uri: "/download/billing/security-deposit",
          queryString:`gradeId=${this.grade}&type=excel`,
      });
    },
  },
};
</script>
